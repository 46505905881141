.container {
  max-width: 800px;
  margin: auto;
  height: 80vh;
 
  padding: 2rem;
}

.spinner {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner-text {
    text-align: center;
}

.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}