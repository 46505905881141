.resume-edit-steps {
  color: var(--grey);

  .ant-steps-item-tail::after {
    background-color: var(--grey) !important;
  }

  .ant-steps-item-icon {
    background-color: var(--grey) !important;
  }
}
.resume-edit-steps .ant-steps-item {
  min-height: 64px !important;
  /* padding: 12px 0px; */
}
.resume-edit-steps .ant-steps-item .ant-steps-item-title {
  color: var(--white) !important;
  line-height: normal;
  line-height: 18px;
  padding-top: 3px;
}

.resume-edit-steps .ant-steps-item.ant-steps-item-active .ant-steps-item-title {
  color: var(--accent-2) !important;
  opacity: 1;

  line-height: normal;
  font-weight: 600;
}

.resume-edit-steps .ant-steps-item .step-description {
  color: var(--white) !important;

  opacity: 0.7;

  line-height: normal;
}

.resume-edit-steps .ant-steps-item-active .step-description {
  color: var(--primary) !important;
  opacity: 1;

  line-height: normal;
}

.resume-edit-steps .ant-steps-item-disabled .step-description {
  color: var(--primary-400) !important;
  opacity: 0.6;
  line-height: normal;
}

/*  */

.resume-edit-steps .ant-steps-item-title {
  font-weight: 500;
}

.resume-edit-steps .ant-steps-item-active .ant-steps-icon-dot {
  /* outline: 2px solid var(--primary); */
  /* outline: 3px solid #25676359;
  outline-offset: 2px; */
  background-color: var(--accent-2) !important;
  /* height: 16px !important; */
  /* width: 16px  !important; */

  outline: 3px solid var(--accent-2);
}
.detail-form-header {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.detail-form-body {
  margin: 2rem 0rem;
  margin-bottom: 1rem;
}

.resume-edit-detail .detail-form-body{
    margin: 2rem 0rem;
    margin-bottom: 1rem;
  
}

.detail-form-body .cv-input {
  /* margin-bottom: 1rem; */
  min-height: 320px;
}

.detail-form-body .cv-message {
  /* margin-bottom: 1rem; */
  min-height: 64px;
  padding-top: 24px;
}

.resume {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.resume .title-header {

  min-height: 50px;
    
}

.resume .resume-body {
  /* flex: 1; */
  width: 100%;
  height: calc(100% - 50px);
  flex-wrap: nowrap;
}
.resume .ant-tabs-nav::before {
  border-bottom: 1px solid #bed1d0;
}

.resume .resume-steps-col {
  height: 100%;
  background-color: var(--black);
  color: var(--white) !important;
}

.selector-col {
  background: #f3f9fa 0% 0% no-repeat padding-box;
  border-right: #000000 2px solid;
  .ant-btn-default {
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 12px/12px DM Sans !important;
    letter-spacing: 0px;
    color: #ffffff !important;
    opacity: 1;
    height: 30px;
  }

  .ant-btn-default:hover{
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box !important;
    background: #000000 0% 0% no-repeat padding-box !important;
    opacity: 0.8;
    color: #ffffff !important;

  }
}

.editor-container {


  div[contenteditable="true"] {
   height: 200px;
  }
}