body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  padding-inline-start: 20px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.material-symbols-outlined {
  font-size: 16px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

.resume-preview-frame {
  /* width: 15cm;
  height: 22cm; */
  width: 100%;
  border-radius: 6px;
  /* height: 100%; */
  /* border: 0px; */
  /* margin: auto; */
}

.resume-preview-frame {

  /* zoom: 0.75; */
  /* -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0; */
}


.cv-link-button {
  padding: 0px 2px;
  color: var(--primary) !important;
}

.cv-link-button:hover {
  color: var(--primary) !important;
}

.cv-link-button:active {
  color: var(--primary) !important;
}

.menu-selector {
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

.menu-selector .menu-content {
  flex: 1;
}

.menu-selector .empty-content {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-selector .menu-card-details .actions {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}

.menu-selector .menu-card-details:hover .actions {
  opacity: 0;
}

.space {
  width: 100%;
}
.resume-edit-detail.padding{
  margin: 2rem 2rem;
  margin-bottom: 0px;
  /* height: 100%; */
  /* overflow-y: scroll; */
}

.resume-edit-detail {
  height: 100%;
}
.resume-edit-detail .ant-row {
  width: 100%;
}



.body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* overflow: hidden; */
  header {
    height: 64px;
  }

  .layout {
    flex: 1;


  }
}

.react-pdf__Page__canvas {
  border: 2px solid #000;
  margin: 0.5rem 0.5rem;
}

.default-modal {
  width: 620px;
  .ant-modal-content {
    padding: 32px 64px;
  }
}