.all-resume {
  height: 100%;
}
.resume-container {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.resume-card .ant-card-body {
  padding: 0px;
}
.ant-card {
  overflow: hidden;
}
.resume-card .resume-details-actions {
  padding: 0px 16px;
  padding-bottom: 16px;
}
.resume-card-preview-image {
  border: solid 2px #cccccc;
  border-radius: 8px;
}
.resume-card-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.resume-card-header {
  /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem; */
  width: 100%;
  padding: 0.25rem 0.5rem;
}

.resume-card-header .title {
  margin-bottom: 0.5rem;
}

.resume-card-header .subtitle {
}

.resume-body {
  width: 980px;
  margin: 32px auto;
}

.body-header {
  margin: 48px 0px;

  .subtitle {
    height: 39px;
    /* UI Properties */
    color: var(--black);
    font: normal normal bold 30px/12px DM Sans;
    letter-spacing: 0px;
    opacity: 1;
  }

  .subtitle {
    height: 54px;
    /* UI Properties */
    color: var(--black);
    text-align: left;
    font: normal normal normal 16px/24px DM Sans;
    letter-spacing: 0px;
    opacity: 1;
  }
}

.resume-card-new.ant-card {
  box-shadow: 0px 3px 6px #0000001a;
  border: 1px solid #ececec;
  border-radius: 10px;
  opacity: 1;
}

.resume-card-new {
  .ant-card-body {
    padding: 0px;
    height: 260px;
  }

  .resume-details-actions {
    padding: 32px 32px;
  }

  .title {
    /* height: 22px; */
    text-align: left;
    font: normal normal bold 16px/18px DM Sans;
    letter-spacing: 0px;
    color: #1d1d1d;
    opacity: 1;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .subtitle {
    height: 18px;
    text-align: left;
    font: normal normal normal 12px/18px DM Sans;
    letter-spacing: 0px;
    color: #1d1d1d;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-column {
    margin-top: 12px;
  }

  .resume-action {
    font: normal normal normal 12px/12px DM Sans !important;
    letter-spacing: 0px;
    color: var(--black);

    i {
      width: 16px;
      height: 16px;
      color: var(--accent-1);
      opacity: 1;
      margin-right: 8px;
    }
  }

  .expand-icon {
    position: absolute;
    top: 8px;
    right: 16px;
    height: 32px;
    border-radius: 50%;
    width: 32px;

  }
}

.preview-container {
  background-color: #80808033;
  overflow: hidden;
  height: 100%;
  padding: 32px;
}

.resume-card-new:hover {
  box-shadow: 0px 3px 6px #0000001a;
  border: 2px solid #000000;
  border-radius: 10px;
  opacity: 1;

  .preview-container {
    background-color: #000000;
  }
}

.new-resume-modal {
  width: 620px;
  .ant-modal-content {
    padding: 32px 64px;
  }

  .header {
    text-align: center;
  }

  h4 {
    height: 39px;
    /* UI Properties */
    color: var(--black);
    text-align: center;
    font: normal normal bold 30px/12px DM Sans;
    /* letter-spacing: 0px; */
    opacity: 1;
    margin: 0px;
  }
  .wizard-logo {
    margin-bottom: 12px;
  }

  .subtitle {
    color: var(--black);
    text-align: center;
    font: normal normal normal 16px/24px DM Sans;
    letter-spacing: 0px;
    opacity: 1;
  }

  .submit-btn {
    display: flex;
    justify-content: center;
  }
}
