:root {
  /* --primary: #256763;
  --secondary: #3b73ce;
  --danger: #fd6764;
  --primary-400: #518582;
  --primary-500: #3b7673;
  --primary-200: #bed1d0;
  --accent-200: #f0c3b9;
  --accent-400: #e18673; */

  --unnamed-color-f3f9fa: #f3f9fa;
  --unnamed-color-d9d9d9: #d9d9d9;
  --black: #000000;
  --dark-grey: #484848;
  --light-black: #818181;
  --grey: #d9d9d9;
  --accent-3: #ffe86d;
  --accent-2: #fa9f7b;
  --accent-2-lighter: #FCCFBC;
  --accent-2-light: #fa9f7b51;

  --accent-2-dark: #fa9f7b80;
  --primary: #37fdaa;
  --accent-1: #a774ff;
  --white: #ffffff;
}
.App {
  text-align: center;
}

body {
  /* background: rgb(195, 208, 231); */
  /* background: radial-gradient(
    circle,
    rgba(195, 208, 231, 0.5508797268907564) 0%,
    rgba(247, 233, 221, 0.5508797268907564) 80%
  ); */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.layout {
  display: flex;
  /* height: 100vh; */
  height: calc(100vh - 64px);
}
#detail {
  height: 100%;
  /* height: 100vh; */
  /* padding: 1rem 2rem; */
  /* min-height: calc(100vh - 64px - 10px - 115px); */
  /* overflow-y: scroll; */
  flex: auto;
}

#detail h2 {
  /* margin-top: 1rem; */
}
#public-detail {
  min-height: calc(100vh - 64px - 10px - 115px);
}
#root {
  min-height: 100vh;
}
.pdf-view {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
  /* border: none; */
  -webkit-transition: box-shadow 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out !important;
  transition: box-shadow 0.3s ease-in-out !important;
  height: 800px;
  overflow-y: scroll;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo h3 {
  font-family: "Barlow Condensed", sans-serif;
  /* font-family: "Manrope", sans-serif; */

  color: #214683;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 800;
}
.header .ant-menu-overflow {
  border: 0 !important;
}

.header .ant-btn-link {
  background-color: #f8f2f3;
}

.header .ant-btn {
  margin: 0 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#detail {
  /* max-width: 1400px; */
  /* padding: 1rem 2rem; */
  /* margin: auto;
  margin: 0 2rem; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

#detail-large {
  display: flex;
  height: 100%;
  /* padding: 1rem 2rem; */
  margin: auto;
  /* overflow-y: scroll; */
  flex: auto;
  flex-direction: column;
  /* min-height: 100vh; */
}

.outlet {
  flex: auto;
  height: 100%;
}

#public-detail {
  /* padding-top: 25px; */
  /* max-width: 1200px; */
  margin: auto;
}

.public-header {
  background-color: transparent;
  height: 75px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  margin-top: 0.125rem;
}

.public-header h3 {
  color: var(--primary);
  font-weight: 600;
}

.nav-menu .ant-btn {
  /* font-weight: 600; */
  color: var(--black) !important;
}
/* .public-header .nav-menu .ant-btn:active {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header .nav-menu .ant-btn-background-ghost:hover {
  color: rgb(255 255 255);
  border-color: rgb(255 255 255);
}

.public-header .nav-menu .ant-btn-primary {
  color: var(--primary);
  background-color: rgb(255 255 255);
  border-color: rgb(255 255 255);
} */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  /* background-color: #f7f8fa;
   */
  /* background-color: #e9efee; */

  /* font-family: "Open Sans", "Roboto", sans-serif; */
  line-height: 1.5;
}

main {
  margin-top: 95px;
}

input,
button,
textarea,
select {
  font: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  overflow-wrap: break-word;
}

p {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea,
select {
  font: inherit;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  /* padding: 0 10px; */
  font-size: 14px;
}

.card-actions {
  margin-top: auto;
  padding-top: 1.5rem;
}
/* 
.button {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  position: relative;
} */
/* 
.button::after {
  content: '';
  position: absolute;
}

.button:disabled {
  cursor: default;
  opacity: .8;
} */

/* .button.primary {
  background-color: rgb(130 177 255);
  border-radius: 4px;
  color: rgb(255 255 255);
  padding: 0 20px;
  transition: 0.2s background-color ease-in-out;
}

.button.primary.white {
  background-color: rgb(255 255 255);
  color: rgb(83 96 172);
  font-weight: 500;
}

.button.secondary {
  background-color: transparent;
  color: rgb(0 0 0 / 80%);
}

.button.secondary::after {
  border-radius: 20px;
  border: 1px solid rgb(0 0 0 / 15%);
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.2s all ease-in-out;
  width: 100%;
}

.button.secondary:not(:disabled):hover::after {
  border-radius: 25px;
  height: calc(100% + 5px);
  left: -2.5px;
  top: -2.5px;
  width: calc(100% + 5px);
}

.button.secondary.white {
  color: rgb(255 255 255);
}

.button.secondary.white::after {
  border: 1px solid rgb(255 255 255 / 30%);
} */

.item {
  background-color: rgb(255 255 255);
  border-bottom: 2px solid rgb(0 0 0 / 10%);
  border-radius: 8px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 4%);
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.item.plans {
  background-color: transparent;
  border-bottom: unset;
  border-radius: 8px;
  box-shadow: unset;
  display: flex;
  flex-direction: column;
  padding: unset;
}

.item .icon {
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 15%);
  color: rgb(92 107 192);
  display: inline-flex;
  height: 35px;
  justify-content: center;
  margin-bottom: 10px;
  width: 35px;
}

.item .icon svg {
  height: 20px;
}

.item h4 {
  color: rgb(0 0 0 / 80%);
  font-size: 17px;
  margin: 5px 0;
}

.item p {
  color: rgb(0 0 0 / 60%);
  font-size: 16px;
  line-height: 26px;
}

.item .actions {
  align-items: center;
  display: flex;
  /* margin-top: 10px; */
  padding-top: 1rem;
  margin-top: auto;
}

.item .actions .button {
  margin-right: 10px;
}

.hamburg-menu {
  display: none;
}
@media (max-width: 767px) {
  .hamburg-menu {
    display: block;
    float: right;
  }

  .nav-menu {
    display: none;
  }

  .ant-popover {
    margin: 0px -2.5rem;
  }
}

.popover-content {
  min-width: 200px;
}

.popover-content .ant-space {
  width: 100%;
}

.ant-layout-header {
  padding-inline: 16px;
}

.ant-form-item-label {
  padding: 5px 0px !important;
}

.ant-form-item-label label {
  color: rgba(0, 0, 0, 0.5) !important;
}

.draggable-form-wrapper {
  display: flex;
}

.hover-outlined-button {
  /* position: absolute; */
  /* top: 100px; */
  /* left: 4px; */
  /* transform: translate(0%, 200%); */
  display: flex;
  left: -10px;
  margin-left: -18px;
  padding-right: 4px;
}

.education-form {
  width: 100%;
}

.education-form {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin: 0.5rem 0;
  background-color: white;
}

.openai-generated-content-item {
  border: 1px solid #eee;
  padding: 0.5rem 1.5rem;
  margin: 0.75rem 0.0rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.openai-generated-content-item:hover {
  /* background-color: rgba(0, 128, 255, 0.1); */
  /* color: rgb(0, 128, 255); */
  outline: 2px solid rgba(0, 0, 0, 0.75);
  transition: 0.2s all ease-in-out;
}

.openai-model-content h4 {
  margin-bottom: 0px !important;
}

.openai-model-content .model-header {
  margin-bottom: 0.5rem !important;
}

/* .ant-btn-text {
  color: #6d63e2
}

.ant-btn-text:hover{
  color: #6d63e2 !important;
  background-color: #6d63e21c !important;
  transition: 0.1s all ease-in-out;

} */

/* .ant-btn-link {
  color: #6d63e2
} */

/* .ant-btn-link:hover{
  color: #918adc !important;
  transition: 0.1s all ease-in-out;
}
.ant-btn-link:disabled:hover{
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25) !important;
} */
.spin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.form-tip-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form-error-section {
  height: 1.5rem;
}

.ant-skeleton {
  width: 100% !important;
}
.ant-skeleton-image {
  width: 100% !important;
}

.cv-wizard-box {
  width: 100%;

  padding: 12px;
  border: 1px solid var(--primary-400);
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: var(--primary-200);
}

.cv-wizard-box .ant-typography {
  color: var(--primary);
}

.cv-wizard-box .ant-typography-secondary {
  color: var(--primary-500);
}

.cv-wizard-box svg {
  fill: #e18673;
}

.auth-header .ant-btn-text {
  color: #5a5a5a;
  font-size: 16px;
}

.auth-header .ant-btn-text.active {
  color: var(--black);
  font-weight: 600;
  transition: 0.2s all ease-in-out;
}

.auth-header .ant-btn-text.active::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -6px;
  height: 1px;
  width: 50%; /* or 100px */
  border-bottom: 2px solid var(--primary);
  padding-top: 2px;
  transition: 0.2s all ease-in-out;
}

.auth-header .ant-btn-text.active:hover {
  color: var(--black);
  font-weight: 600;
  background-color: transparent;
}

.auth-header .ant-btn-text:hover {
  color: var(--black) !important;
  font-weight: 400;
  background-color: transparent !important;
}

.auth-header .ant-btn-text.active:after {
  /* color: var(--primary);
  border-bottom: 2px solid var(--primary); */

  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -6px;
  height: 1px;
  width: 50%; /* or 100px */
  border-bottom: 2px solid var(--primary);
  padding-top: 2px;
  transition: 0.2s all ease-in-out;
}

.editor-container {
  background-color: white;
}

.cv-wizard-box .ant-skeleton-content .ant-skeleton-title {
  background: rgb(249, 249, 249);
  background: linear-gradient(
    90deg,
    rgba(215, 238, 237, 0.7189469537815126) 38%,
    rgba(129, 176, 174, 0.6741290266106443) 70%
  );
  background-size: 300% 100%;
}

.cv-wizard-box .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: rgb(249, 249, 249);
  background: linear-gradient(
    90deg,
    rgba(215, 238, 237, 0.7189469537815126) 38%,
    rgba(129, 176, 174, 0.6741290266106443) 70%
  );

  background-size: 300% 100%;
}

.resume-card .expand-icon {
  position: absolute;
  right: 16px;
  top: 16px;
  /* transform: translate(-50%, -50%); */
  border-radius: 50%;
  background: #c2c2c22e;
  padding: 8px;
  backdrop-filter: blur(5px);
  transition: 0.2s all ease-in-out;
}

.resume-card .expand-icon:hover {
  /* transform: translate(-50%, -50%); */

  padding: 10px;
  transition: 0.2s all ease-in-out;
}

.title-header {
  color: var(--white) !important;
  text-align: left;
  font: normal normal bold 20px/30px DM Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: var(--black) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  .ant-btn-default {
    border-radius: 4px;
    font: normal normal bold 12px/15px DM Sans !important;
    height: 27px;
    text-align: center;
    svg {
      margin-right: 0.5rem !important;
    }
  }

  .ant-typography {
    color: var(--white) !important;
    text-align: left;
    font: normal normal bold 20px/30px DM Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.subtitle {
  height: 39px;
  /* UI Properties */
  color: var(--black);
  text-align: left;
  font: normal normal normal 16px/24px DM Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* .ant-form-item-control-input-content input:focus {
  border: 1px solid #818181;
  box-shadow: none;
} */

.ai-wizard-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 18px;
  height: 18px;
  /* UI Properties */
  background: var(--black) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  color: var(--white) !important;
  /* padding: 4px 4px; */
  svg {
    font-size: 10px;
  }
}

.cv-wizard-box-v2 {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border: 1px solid #ececec;
  border-radius: 10px;
  opacity: 1;
  min-width: 300px;

  .header {
    padding: 18px 32px;
    background: var(--accent-2-dark) 0% 0% no-repeat padding-box;
    /* background: #d9d9d9 0% 0% no-repeat padding-box; */
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
  }

  .title {
    font: normal normal bold 14px/18px DM Sans;
    letter-spacing: 0px;
    color: #1d1d1d;
    opacity: 1;
    /* height: 22px; */
  }

  .subtitle {
    text-align: left;
    font: normal normal normal 14px/18px DM Sans;
    letter-spacing: 0px;
    color: #131313;
    opacity: 1;
    min-height: 40px;
    height: unset;
  }

  .wizard-body {
    padding: 18px 26px;
  }

  .header.is-hidded {
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
  }
}

.cv-wizard-box-v2 .ai-wizard-icon {
  position: absolute;
  left: -16px;

  height: 34px;
  width: 34px;
  border: 4px solid var(--accent-2);
  svg {
    height: 14px;
  }
}

.selector-col {
  background: #f3f9fa 0% 0% no-repeat padding-box;
  border-right: #000000 2px solid;
  background-color: white;
  padding: 0px 0px !important;
  height: 100%;
  border-radius: 0px !important;
  .ant-btn-default {
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 12px/12px DM Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.skill-item {
  align-items: center;
  padding: 12px 12px;
  max-width: 240px;
  width: 100%;
  height: 44px;
  font: normal normal normal 14px/20px DM Sans;
  border: 1px solid #818181;
  border-radius: 10px;
  background: var(--white) 0% 0% no-repeat padding-box;

  /* // Clip text overflow:  with ellipsis; */
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.skill-item-add {
}

.skill-input {
  padding: 12px 12px;
  font: normal normal normal 14px/20px DM Sans;

  max-width: 240px;
  height: 44px;
}

.ant-btn {
  i {
    margin-right: 8px;
  }
}

.drag-grip {
  color: var(--accent-1);
}

.selector-side-bar .ant-menu-title-content {
  margin: 12px;
  /* padding: 4px; */

}
.selector-side-bar .selector-title {
  font: normal normal bold 16px/21px DM Sans;
  margin: 16px 0;
  padding-left: 20px;
}


.selector-item {
  display: flex;
  flex-direction: column;
  justify-content: center;


  height: auto;
  min-height: 56px;
  line-height: normal;
  width: unset;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  opacity: 1;
  margin: 12px 0px;
  margin-right: 12px;
  padding: 12px 30px !important;

  position: relative;
  cursor: pointer;
  


  .drag-grip {
    position: absolute;
    left: 12px;
    /* top: 14px; */
    top: 50%;
    transform: translateY(-50%);
  }
}

.selector-item .title {
  font-weight: 500;
  overflow: hidden;
  width: calc(100% - 8px);
  text-overflow: ellipsis;
  color: var(--black);
  text-align: left;
  font: normal normal bold 14px/16px DM Sans;
  letter-spacing: 0px;
  opacity: 1;
  overflow: hidden;
  width: calc(100% - 8px);
  text-overflow: ellipsis;
  text-wrap: nowrap;
  /* text-overflow: ellipsis;
  width: calc(100% - 8px);
  overflow: hidden; */
}

.selector-item .subtitle {
  line-height: normal;
  white-space: normal;
  color: #00000097;
  color: var(--black);
  text-align: left;
  font: italic normal normal 12px/12px DM Sans;
  letter-spacing: 0px;
  opacity: 1;
  height: unset;
}
.selector-item.selected .subtitle {
  line-height: normal;
  white-space: normal;
  color: #256763b6;
}

.selector-item.selected {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #000000;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
  border-right: none !important;
  width: calc(100% + 2px) !important;
  margin-right: 0px !important;
  /* margin-right: 0px; */

}


.auto-save-label-success {
  /* color: #52c41a; */
  color: var(--accent-2);
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.5rem;
}

.auto-save-label-loading {
  color: var(--accent-2);
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.5rem;
} 

.description-input {
  position: relative;

  .description-options{
    position: absolute;
    right: 100px;
    bottom: -24px;
    transform: translateY(-50%);
    z-index: 10;
    background-color: white;
    border: 1px solid var(--black);
    border-radius: 10px;
    padding: 0.15rem;
    border-radius: 0.5rem;
        /* opacity: 0; */
  }

  .editor-container:focus-within {
    border: 1px solid var(--black) !important;
  }
}

.settings-view {
  padding-top: 2rem;
  max-width: 1200px;
  margin: 0px auto;
}