.ant-btn-default.small-dark-btn {
  height: 30px;
  color: #ffffff !important;
  background-color: black;
  padding: 8px 36px;
  font: normal normal bold 12px/12px DM Sans !important;

}
.ant-btn-default.small-dark-btn:hover {
  height: 30px;

  background-color: #000000 !important;
  color: #ffffff !important;
  opacity: 0.8;
  font: normal normal bold 12px/12px DM Sans !important;;

}

.ant-btn-default.small-light-btn {
  height: 30px;
  color: #000000 !important;
  background-color: #ffffff;
  padding: 8px 36px;
  font: normal normal bold 12px/12px DM Sans !important;
}

.ant-btn-default.small-light-btn:hover {
  height: 30px;
  color: #000000 !important;
  background-color: #ffffff !important;
  padding: 8px 36px;
  font: normal normal bold 12px/12px DM Sans !important;
  opacity: 0.8;
}

.ant-btn-default.white-btn {
  height: 30px;
  color: #000000 !important;
  background-color: #ffffff;
  padding: 8px 36px;
  font: normal normal bold 12px/12px DM Sans !important;
}