/* .pricing-card.selected {
  border: 2px solid var(--accent-1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  outline: none;
} */

.pricing-card {
  /* cursor: pointer; */
  border: 2px solid black;
}
.pricing-card:hover {
  border: 2px solid black;
}

.pricing-card .feature-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: start;
  /* padding: 1rem 0rem; */

  svg {
    padding-top: 0.4rem;
    padding-right: 0.25rem;
  }
}

.pricing-card .features {
  font-size: 12px;
  padding: 0.25rem 0rem;
}

.pricing-card .upgrade-button {
  margin: 0.5rem 0rem;
  justify-content: center;
  text-align: center;
  /* padding: 0.5rem 0rem; */
}

.enterprise-plan-card {
  flex: 1;
  background-color: black !important;
  color: white !important;
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: 24px;
  border-radius: 12px;

  .small-light-btn {
    height: 40px;
  }

  .small-light-btn:hover {
    height: 40px;
  }
}

.second-pricing-card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.enterprise-message {
  font: normal normal bold 16px/24px DM Sans;
  width: 60%;
}

.pricing-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-top: 1rem;

  .plan-name {
    font: normal normal bold 24px/20px DM Sans;
    /* color: var(--accent-1); */
    /* color: var(--primary); */
  }

  .plan-price {
    font: normal normal bold 24px/20px DM Sans;
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;

    padding: 0rem 0rem !important;
    /* color: var(--primary); */
    color: var(--accent-1);
  }

  .plan-period {
    /* border: 1px solid var(--accent-1); */
    /* border-radius: 5px; */
    padding: 0.25rem 0rem;
    font: normal normal normal 14px/14px DM Sans;
    /* color: var(--accent-1); */
  }

  .plan-price-per-month {
    margin-bottom: 1rem;
  }
}

.subcription-options .header {
  width: 50%;
  margin: 0px auto;
  text-align: center;

  h3 {
    font: normal normal bold 26px/36px DM Sans !important;
  }
}


.enterprise-plan-form {
  .info {
    padding: 2rem 2rem;
    background-color: var(--accent-2-lighter);
    height: 100%;
    border-radius: 12px;
  }
  .feature-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: start;
    padding: 0.375rem 0rem;
  
    svg {
      padding-top: 0.4rem;
      padding-right: 0.25rem;
      color: black;
    }
  }

  
}

