.reset-password .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.reset-password {
  max-width: 600px;
  margin: 0px auto;
  padding: 0px 1.5rem;
}
.reset-password .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.login-signup-page {
  /* max-width: 600px; */
  /* margin: 0px auto; */
  /* padding: 0px 1.5rem; */
}

.login-signup-page .header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.login-signup-page .header h1 {
  color: var(--primary);
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
}

.large-logo {
  /* font-family: "Barlow Condensed", sans-serif;
  color: var(--primary-400);
  margin: 0;
  letter-spacing: 4px;
  font-weight: 800;
  font-size: 64px;
   */

  color: var(--white);
  text-align: left;
  font: normal normal bold 50px/45px DM Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.logo-subtitle {
  color: var(--white);
  text-align: left;
  font: normal normal normal 35px/45px DM Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 0.5rem;
}

.form-title {
  color: var(--black);
  text-align: left;
  font: normal normal bold 30px/12px DM Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.google-btn.ant-btn-default.ant-btn-default:hover {
    background-color: #ffffff !important;
  }
