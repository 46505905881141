.profile {
  height: 100%;
}
.profile .ant-tabs-nav::before {
  border-bottom: 1px solid #bed1d0;
}

.profile-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-tab .ant-tabs {
  height: 100%;
}

.profile-tab .ant-tabs-nav {
  background: var(--black) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
}

.profile-tab .ant-tabs-nav .ant-tabs-tab {
  color: var(--white) !important;
  font: normal normal normal 14px/18px DM Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  height: 41px;
}

.profile-tab .ant-tabs-nav .ant-tabs-tab svg {
  margin-right: 12px !important;
  color: var(--white) !important;
  color: #ffffff;
}

.profile-tab .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  font: normal normal normal 14px/18px DM Sans !important;
  letter-spacing: 0px;
  color: #000000;

  opacity: 1;
}

.profile-tab .ant-tabs-nav .ant-tabs-tab-active svg {
  font: normal normal normal 14px/18px DM Sans !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}

.profile-tab .ant-tabs-nav .ant-tabs-tab-active {
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #f3f9fa 0% 0% no-repeat padding-box;
  opacity: 1;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  /* margin: 0px 32px !important;
  padding-top: 32px !important; */
  padding-left: 0px !important;
}

.ant-tabs-content.ant-tabs-content-left {
  height: 100%;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}

.selector-col {
  background: #f3f9fa 0% 0% no-repeat padding-box;
  border-right: #000000 2px solid;
  .ant-btn-default {
    background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
    background: #000000 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 12px/12px DM Sans;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.ant-menu-item {
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 1.5px solid var(--gray);
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  opacity: 1;
  margin: 1.25rem 1.5rem !important;
  padding: 0px 12px !important;
}

.ant-menu-item.ant-menu-item-selected {
  background: var(--white) 0% 0% no-repeat padding-box !important;
  border: 1.5px solid var(--black);
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #000000;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
  border-right: none !important;
  width: calc(100% - 22px) !important;
}

.ant-menu-item.ant-menu-item-selected::after {
  /* content: "CCCC"; */
  border-right: 2px solid #bed1d0;
  position: absolute;
  height: 100%;
  width: 20px;
  top: 0;
  background-color: white;
  right: -5px;
  z-index: 1000;
}

.ant-menu-title-content {
  .title {
    color: var(--black);
    text-align: left;
    font: normal normal bold 14px/12px DM Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .subtitle {
    height: unset !important;
    color: var(--black);
    text-align: left;
    font: italic normal normal 12px/12px DM Sans;
    letter-spacing: 0px;
    color: #000000;
    /* opacity: 0.8; */
  }
}

.personal-info-form .detail-form-body {
  width: 70%;
}

.profile-tab-detail {
  display: flex;
  flex-direction: row;
  width: 100%;

  .user-input-area {
    width: 70%;
    padding-right: 12px;
  }

  .ai-wizard-area {
    width: 30%;
  }
}
.user-input-area {
  width: 70%;
}

.ai-wizard-area {
  width: 30%;
}
.profile-input-section-divider {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 70%;
  min-width: 70%;
}

.profile-input-section-title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  span {
    font: normal normal bold 16px /21px DM Sans !important;
    letter-spacing: 0px;
    color: #131313;
    text-transform: uppercase;
    /* display: flex; */
    /* align-items: center; */
    gap: 12px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  margin: 8px 0px;
}
.actions .ant-btn-link {
  color: var(--black);
  text-align: center;
  font: normal normal bold 12px/12px DM Sans !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  margin-left: auto;
  padding: 4px 4px;
  /* height: 16px; */
}

.actions.large .ant-btn-link {
  font: normal normal bold 14px/14px DM Sans !important;
  
}

.actions .ant-btn-link:hover {
  color: var(--black) !important ;
  opacity: 0.7;
}
