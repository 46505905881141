.profile-pic-container {
    display: flex;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 100%; */
  /* height: 100%; */
  /* border-radius: 50%; */
  overflow: hidden;
  position: relative;

}

.profile-pic-overlay {

  position: absolute;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* font-size: 1.5rem; */
  /* opacity: 0; */
  transition: opacity 0.3s;
  /* transform: translateY(-50%); */
  /* z-index: 1; */
  overflow: hidden;
  top: 0;
}

.profile-pic-overlay-button {
  width: 128px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.25rem 0.25rem;
  border-radius: 5px;
  transition: background-color 0.3s;
  position: absolute;
  z-index: 1;
  bottom: 0;
}
