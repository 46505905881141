.adjustment-section {
  height: 100%;
  padding: 0px 0px !important;
}
.adjustment-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--black) !important;
  font: normal normal bold 14px/18px DM Sans !important;
  /* height: 41px; */
  /* border-bottom: 2px solid var(--primary); */
}

.adjustment-section .ant-tabs .ant-tabs-tab:hover {
  color: var(--black) !important;
  opacity: 0.6;
}

.adjustment-section .left {
  background: var(--unnamed-color-f3f9fa) 0% 0% no-repeat padding-box;
  height: 100%;
  overflow: hidden;
}

.adjustment-section .ant-col {
  padding: 2rem 3rem;
}

.detail-form-header {
  /* margin: 1rem 1rem; */
}

.template-card {
  img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
  }
}
.adjustment-section .ant-tabs {
  height: 100%;
}
.adjustment-section .ant-tabs-content-holder {
  height: 100%;
  overflow-y: auto;
}
.adjustment-section .ant-tabs-content.ant-tabs-content-top {
  height: 100%;
}

.template-card {
  padding: 0rem 0rem !important;

  .template-card-container {
    margin: 0.5rem 1rem !important;
    background-color: #d9d9d9;
    border-radius: 8px;
    padding: 1.5rem 1.5rem;
  }
}

.template-card.selected{
  .template-card-container {
    background-color: var(--primary);
  }
}
.section-item {
  margin: 1rem 0rem;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001a;
  border-radius: 12px;
  opacity: 1;
  width: 394px;
  height: 59px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 1.5rem;
  font: normal normal normal 16px/18px DM Sans;
  color: #1D1D1D;

  .drag-grip {
    margin-right: 1rem;
  }
}

.sort-item-container {
  /* display: none; */
  /* list-style:none; */
  list-style-type: none;

  .ant-checkbox-wrapper-checked .ant-checkbox-inner{
    background-color: var(--accent-1) !important; 
    border-color: var(--accent-1) !important; 
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner{
    /* background-color: var(--accent-1) !important; */
    border-color: var(--accent-1) !important;
  }
}

