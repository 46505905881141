.corousel-selector{
    /* position: relative; */
    overflow: hidden;
    width: 100%;
  
}

.carousel-inner {
    display: flex;
    width: 100%;
  
    
}

.corousel-selector-item {
    flex: 0 0 100%;
  width: 100%;
}
.corousel-selector .corousel-selector-item{
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* .corousel-selector .corousel-selector-item-hidden{
    display: none;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.corousel-selector .corousel-selector-item-selected{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fadeVisibility 0.5s; 

} */

/* hanlde smooth transitions between slides */
@keyframes fadeVisibility {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}